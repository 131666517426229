import React, { useCallback, useState } from 'react';
import { IMedia, MediaFormat, MediaType } from '@types';
import { PresentationFormat } from 'src/types/media';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import { apiClients } from '@api';
import { ApiResponse } from '@api/types';
import { useDispatch } from 'react-redux';
import storage from '@storage';

interface PresentationProps {
  value?: any;
  onClick?: (data: IMedia, e: React.MouseEvent<HTMLImageElement | HTMLVideoElement | SVGSVGElement>) => void;
}

export default function Presentation(props: PresentationProps) {
  const dispatch = useDispatch();

  const [item, setItem] = useState(getMedia(props.value));
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onDelete = useCallback(async (item?: IMedia) => {
    if (item) {
      try {
        setDeleting(true);
        const response = await apiClients.base.delete<ApiResponse>(`api/admin/file/${item.fileId}`);
        if (response.errorCode) {
            const error = `${response.errorCode}: ${response.errorMsg ?? 'Ошибка сервера'}`;
            dispatch(storage.toast.setError(error));
            return;
        } else {
            setItem({});
        }
      } finally {
        setDeleting(false);
      }
    }

    setVisibleConfirm(false);
  }, []);
  
  return (
    <>
        {
            item && item.url 
                ? <>
                    В проект прикреплён файл <a href={item.url} download>Скачать</a>  <a style={{ textDecoration: "underline" }} onClick={() => setVisibleConfirm(true)}>Удалить</a>
                </> 
                : <></>
        }
            
        <ConfirmationModal
            data={item}
            visible={visibleConfirm}
            title={'Удаление файла'}
            content={'Вы точно хотите удалить файл из проекта?'}
            confirmText={'Удалить'}
            confirming={deleting}
            onCancel={() => setVisibleConfirm(false)}
            onConfirm={onDelete}
            unmountOnClose
        />
    </>
  );
}

const getPresentationFormat = (url?: string): PresentationFormat | undefined => {
    if (url) {
      const normalized = url.toLowerCase().trim();
      if (normalized.endsWith('.ppt')) return 'Ppt';
      if (normalized.endsWith('.pptx')) return 'Pptx';
      if (normalized.endsWith('.pdf')) return 'Pdf';
    }
};

const getMediaFormat = (url?: string): MediaFormat => (
    getPresentationFormat(url) ?? 'Unknown'
);
  
const getMediaType = (url?: string): MediaType | undefined => {
    const imageFormat = getPresentationFormat(url);
    if (imageFormat) {
      return 'Presentation';
    }
};

const getMedia = (value: any): IMedia => {
    if (value === undefined) {
        return {};
    }
    if (typeof value === 'string') {
      return {
        url: value,
        type: getMediaType(value),
        format: getMediaFormat(value)
      };
    }
    if (typeof value === 'object') {
      return {
        ...value,
        type: value.type ?? getMediaType(value.url),
        format: value.format ?? getMediaFormat(value.url)
      };
    }
    return {};
  };
